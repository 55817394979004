<template>
  <div class="bg-light b-30 p-4">
    <sk-list v-if="loading" :height="0.03" :items-count="6"></sk-list>
    <div v-if="!loading && requests.length === 0" class="text-center">
      {{ $t('cam-requests.requests-not-found') }}
    </div>
    <table v-if="!loading && requests.length > 0" class="table-blue-wide w-100">
      <thead>
      <tr>
        <th>{{ $t('cam-requests.cam') }}</th>
        <th>{{ $t('cam-requests.sent-date') }}</th>
        <th>{{ $t('cam-requests.request-date') }}</th>
        <th class="w-25">{{ $t('cam-requests.comment') }}</th>
        <th class="w-13 text-center">{{ $t('cam-requests.status') }}</th>
        <th class="w-13 text-center">{{ $t('cam-requests.actions') }}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="request of requests">
        <td :data-label="$t('cam-requests.cam')" v-html="request.cams.map(o => o.name).join('<br />')"></td>
        <td :data-label="$t('cam-requests.sent-date')">{{ moment(request.created_at).format('YYYY-MM-DD HH:mm') }}</td>
        <td :data-label="$t('cam-requests.request-date')">
          {{ moment(request.date_from).format('YYYY-MM-DD HH:mm') }} / {{ moment(request.date_to).format('YYYY-MM-DD HH:mm') }}
        </td>
        <td :data-label="$t('cam-requests.comment')"> <span>{{ request.comment }}</span> </td>
        <td :data-label="$t('cam-requests.status')" class="text-center">
          <img :src="getIcon(request)" class="img-fluid mr-2 mr-sm-3 p-0 p-sm-1 ic">
        </td>
        <td :data-label="$t('cam-requests.actions')" class="text-center">
          <a v-if="request.can_delete" @click="deleteBooking(request)" class="cursor-pointer" :title="$t('cam-requests.delete-booking')">
            <img src="/static/images/icons/evaluations/fail-ic.png" class="img-fluid mr-2 mr-sm-3 p-0 p-sm-1 ic">
          </a>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import SkList from '../skeletons/sk-list';
import moment from 'moment';
import CinematographyCamRequestResource from '../../api/cinematographyCamRequestResource';

const camRequestResource = new CinematographyCamRequestResource();

export default {
  name: 'request-list',
  components: { SkList },
  data() {
    return {
      requests: [],
      loading: false
    }
  },

  mounted() {
    this.loadBookings();
  },

  methods: {
    loadBookings() {
      this.loading = true;
      camRequestResource.myRequests().then(response => {
        this.requests = response.data.data;
        this.loading = false;
      });
    },

    deleteBooking(request) {
      camRequestResource.deleteBooking(request.cam_requestID).then(response => {
        this.loadBookings();
      });
    },

    moment: function (date) {
      return moment(date);
    },

    getIcon(request) {
      if(request.is_approved == 1)
        return '/static/images/icons/evaluations/accept.png';

      if(request.is_approved == 0)
        return '/static/images/icons/evaluations/fail.png';

      return '/static/images/icons/evaluations/recover.png';
    }
  }
};
</script>
<style scoped>
  td {
    min-height: 40px;
  }
</style>
