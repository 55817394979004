<template>
    <div class="container-fluid">
        <div class="row">
            <sidebar></sidebar>
            <!-- MAIN CONTENT start -->
            <main role="main" class="col-12 col-sm-9 ml-sm-auto col-lg-10">
                    <div class="row">
                        <div class="col-12 mb-5">
                            <app-header></app-header>
                            <page-header :page-title="pageData.title" :breadcrumb="pageData.breadcrumb"></page-header>
                        </div>
                    </div>
                        <div class="row">
                          <div class="col-12 col-lg-6">
                            <div class="border-round d-lg-flex align-items-center justify-content-start flex-wrap flex-lg-nowrap p-3 bg-white-88">
                              <div class="d-lg-flex align-items-center mr-4 pr-2 mt-3 mt-lg-0">
                                <img src="/static/images/icons/evaluations/recover.png" alt="sent" class="img-fluid mr-2 mr-sm-3 p-0 p-sm-1 ic">
                                <span class="s-b-1">{{ $t('cam-requests.sent') }}</span>
                              </div>
                              <div class="d-lg-flex align-items-center mr-4 pr-2 mt-3 mt-lg-0">
                                <img src="/static/images/icons/evaluations/accept.png" alt="satisfied" class="img-fluid mr-2 mr-sm-3 p-0 p-sm-1 ic">
                                <span class="s-b-1">{{ $t('cam-requests.approved') }}</span>
                              </div>
                              <div class="d-lg-flex align-items-center mr-4 pr-2 mt-3 mt-lg-0">
                                <img src="/static/images/icons/evaluations/fail.png" alt="notsatisfied" class="img-fluid mr-2 mr-sm-3 p-0 p-sm-1 ic">
                                <span class="s-b-1">{{ $t('cam-requests.canceled') }}</span>
                              </div>
                            </div>
                          </div>

                          <div class="col-12 col-lg-6 text-right">
                            <button @click="handleNewRequestClick()" class="border-round btn bg-red text-white mt-lg-0 mt-3 py-3 s-b-1">{{ $t('cam-requests.book-cam') }}</button>
                            <request-modal></request-modal>
                          </div>

                            <!-- application table -->
                            <div class="col-12 mt-5">
                                <request-list></request-list>
                            </div>
                        </div>
            </main>
        </div>
    </div>
</template>
<script>
import sidebar from '../components/navbar/sidebar';
import appHeader from '../components/app-header';
import pageHeader from '../components/page/page-header';
import RequestList from '../components/cinematography-cam-requests/request-list';
import RequestModal from '../components/cinematography-cam-requests/request-modal';

export default {
    name:'cinematography-cam-requests',
    components: { sidebar, appHeader, pageHeader, RequestList, RequestModal },
    computed: {
        pageData() {
        return {
            title: this.$t('cam-requests.title'),
            breadcrumb: [{
            icon: '',
            title: this.$t('home.title'),
            link: '/',
            isActive: false,
            },
            {
            icon: '',
            title: this.$t('cam-requests.title'),
            link: '/cam-requests',
            isActive: true,
            }],
        };
        },
    },

  methods: {
    handleNewRequestClick() {
      this.$bvModal.show('request-modal');
    }
  }
}
</script>
