import axios from 'axios';

export default class CinematographyCamRequestResource {
  myRequests() {
    return axios.get('/api/cinematography-cam-requests/my-requests');
  }

  subjects() {
    return axios.get('/api/cinematography-cam-requests/subjects');
  }

  freeCams(dateFrom, dateTo) {
    return axios.get('/api/cinematography-cam-requests/free?date_from='+dateFrom+'&date_to='+dateTo);
  }

  requests(dateFrom, dateTo) {
    return axios.get('/api/cinematography-cam-requests/requests?date_from='+dateFrom+'&date_to='+dateTo+'&for_calendar=1');
  }

  bookCam(data) {
    console.log(data);
    return axios.post('/api/cinematography-cam-requests', data);
  }

  requestTypes() {
    return axios.get('/api/cinematography-cam-requests/request-types');
  }

  deleteBooking(requestID) {
    return axios.delete('/api/cinematography-cam-requests/requests/'+requestID);
  }
}
