<template>
  <div>
    <b-modal
      @shown="onShown()"
      :hide-header="true"
      :hide-footer="true"
      content-class="modal-blue"
      id="confirm-request-modal"
      size="md"
      scrollable
      centered>
      <!-- Modal content-->
      <div class="row ">
        <div class="col mb-5 text-left">
          <h4 class="mb-4">{{ $t('cam-requests.form.confirm-details') }}</h4>

          <div class="row">
            <div class="col-12 mb-5 font-weight-bold">
              <div>ვიდეო კამერის მოთხოვნის დრო</div>
              <div class="text-danger">{{ form.date_from }}-დან</div>
              <div class="text-danger">{{ form.date_to }}-მდე</div>
            </div>
            <b-form-group class="col-6">
              <label for="dateFrom" class="font-weight-bold">{{ $t('cam-requests.form.book-date-from') }}</label>
              <b-form-datepicker :date-format-options="datepickerFormat"
                                 id="dateFrom"
                                 v-model="dateFrom"
                                 :locale="locale"></b-form-datepicker>
            </b-form-group>
            <b-form-group class="col-6">
              <label for="dateTo" class="font-weight-bold">{{ $t('cam-requests.form.book-date-to') }}</label>
              <b-form-datepicker :date-format-options="datepickerFormat"
                                 id="dateTo"
                                 v-model="dateTo"
                                 :locale="locale"></b-form-datepicker>
            </b-form-group>

            <b-form-group class="col-6">
              <label for="timeFrom" class="font-weight-bold">{{ $t('cam-requests.form.book-time-from') }}</label>
              <b-form-timepicker id="timeFrom"
                                 :hour12="false"
                                 v-model="timeFrom"
                                 :locale="locale"></b-form-timepicker>
            </b-form-group>
            <b-form-group class="col-6">
              <label for="timeTo" class="font-weight-bold">{{ $t('cam-requests.form.book-time-to') }}</label>
              <b-form-timepicker id="timeTo"
                                 :hour12="false"
                                 v-model="timeTo"
                                 :locale="locale"></b-form-timepicker>
            </b-form-group>

            <b-form-group class="col-12">
              <label class="font-weight-bold" for="camID">{{ $t('cam-requests.form.video-cam') }}</label>
              <multiselect
                id="camID"
                v-model="chosenCams"
                :options="cams"
                track-by="camID"
                :label="getCamDropdownLabel()"
                :multiple="true"
                :placeholder="$t('cam-requests.form.choose-cam')"
              ></multiselect>
            </b-form-group>

<!--            <b-form-group class="col-12">-->
<!--              <label class="font-weight-bold" for="requestTypeID">{{ $t('cam-requests.form.request-type') }}</label>-->
<!--              <multiselect-->
<!--                id="requestTypeID"-->
<!--                v-model="chosenRequestType"-->
<!--                :options="requestTypes"-->
<!--                track-by="cam_request_typeID"-->
<!--                :label="getRequestTypeDropdownLabel()"-->
<!--                :placeholder="$t('cam-requests.form.choose-type')"-->
<!--              ></multiselect>-->
<!--            </b-form-group>-->

            <b-form-group class="col-12">
              <label class="font-weight-bold" for="location">{{ $t('cam-requests.form.location') }}</label>
              <b-form-input v-model="form.location" id="location"></b-form-input>
            </b-form-group>

            <b-form-group class="col-12" :label="$t('cam-requests.form.comment')">
              <b-form-textarea v-model="form.comment"></b-form-textarea>
            </b-form-group>

            <b-form-checkbox
              id="checkbox-1"
              v-model="form.agree"
              name="checkbox-1"
              value="1"
              unchecked-value=""
              class="pl-5"
            >
              გავეცანი და ვეთანხმები <a :href="agreementLink" target="_blank">ხელშეკრულების პირობებს</a>
            </b-form-checkbox>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col mt-0 pt-3 mt-sm-5 pt-sm-0 mb-3">
          <button v-if="!loading" type="button" class="btn btn-light-bold" @click="handleBookClick()">{{ $t('cam-requests.form.book') }}</button>
          <button type="button" class="btn btn-light-bold" @click="hideModal()">{{ $t('cam-requests.close') }}</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect';
  import { mapGetters } from 'vuex';
  import CinematographyCamRequestResource from '../../api/cinematographyCamRequestResource';
  import moment from 'moment';

  const camRequestResource = new CinematographyCamRequestResource();

  export default {
    name: 'confirm-request-modal',

    components: { Multiselect },

    props: ['propDateFrom', 'propDateTo'],

    computed: {
      ...mapGetters({
        locale: 'language/locale',
        user: 'auth/user'
      }),
      agreementLink() {
        const now = moment().format('DD-MM-YYYY');
        const student = this.user.fullName
        const personalId = this.user.personalId
        const from = this.form.date_from
        const to = this.form.date_to
        const cams = this.chosenCams.map(o => o.name).join(', ')

        return `${process.env.ONLINE_UG}book_cinematography_cams.php?agreement=1&date=${now}
        &student=${student}&personal_id=${personalId}&from=${from}&to=${to}&cams=${cams}`
      }
    },

    data() {
      return {
        datepickerFormat: { 'year': 'numeric', 'month': 'numeric', 'day': 'numeric' },
        dateFrom: '',
        dateTo: '',
        timeFrom: '',
        timeTo: '',
        form: this.emptyForm(),
        chosenCams: [],
        // chosenRequestType: null,
        chosenSubject: null,
        cams: [],
        // requestTypes: [],
        loading: false
      }
    },

    watch: {
      dateFrom() {
        this.form.date_from = this.dateFrom + ' ' + this.timeFrom;
        this.loadCams();
      },

      dateTo() {
        this.form.date_to = this.dateTo + ' ' + this.timeTo;
        this.loadCams();
      },

      timeFrom() {
        this.form.date_from = this.dateFrom + ' ' + this.timeFrom;
        this.loadCams();
      },

      timeTo() {
        this.form.date_to = this.dateTo + ' ' + this.timeTo;
        this.loadCams();
      },
    },

    methods: {
      handleBookClick() {
        this.form.cam_ids = this.chosenCams.length > 0 ? this.chosenCams.map(o => o.camID) : [];
        // this.form.cam_request_type_id = this.chosenRequestType ? this.chosenRequestType.cam_request_typeID : '';
        this.form.subject_id = this.chosenSubject ? this.chosenSubject.subjectID : '';

        this.loading = true;
        camRequestResource.bookCam(this.form)
        .then(response => {
          this.$bvModal.hide('confirm-request-modal');
          this.helpers.notifySuccessMessage(this.$t('cam-requests.form.success'));

          this.$emit('add-request');
        })
        .catch(error => {
          this.helpers.notifyErrorMessage(error);
        })
        .finally(() => {
          this.loading = false;
        });
      },

      emptyForm() {
        return {
          date_from: '',
          date_to: '',
          cam_ids: [],
          location: '',
          comment: '',
          agree: ''
        }
      },

      getCamDropdownLabel() {
        return this.$i18n.locale === 'ka' ? 'name' : 'nameeng';
      },

      loadCams() {
        camRequestResource.freeCams(this.form.date_from, this.form.date_to)
        .then(response => {
          this.cams = response.data.data;

          if(this.cams.length === 0) {
            this.helpers.notifyWarningMessage(this.$t('cam-requests.form.free-cams-not-found'))
          }
        });
      },

      // loadRequestTypes() {
      //   camRequestResource.requestTypes().then(response => {
      //     this.requestTypes = response.data.data;
      //   })
      // },

      hideModal() {
        this.form = this.emptyForm();
        // this.chosenRequestType = null;
        this.chosenCams = [];

        this.$bvModal.hide('confirm-request-modal');
      },

      onShown() {
        this.dateFrom = this.propDateFrom.format('YYYY-MM-DD');
        this.dateTo = this.propDateTo.format('YYYY-MM-DD');
        this.timeFrom = this.propDateFrom.format('HH:mm:ss');
        this.timeTo = this.propDateTo.format('HH:mm:ss');

        this.loadCams();

        // if(this.requestTypes.length === 0)
        //   this.loadRequestTypes();
      },
    }
  };
</script>
<style scoped>
  @import '~bootstrap-vue/dist/bootstrap-vue.min.css';
  @import "~vue-multiselect/dist/vue-multiselect.min.css";
</style>
